<template>
  <ServidorLogin />
</template>

<script>
import ServidorLogin from '@/components/servidorLogin/ServidorLogin'

export default {
  name: 'LoginIndex',

  components: {
    ServidorLogin,
  },

  created() {
    document.title = 'Portal do Servidor'
  },
}
</script>
